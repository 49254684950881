import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Divider,
  Typography,
  Spin,
  Result,
  Dropdown,
  Segmented,
  Avatar,
  Image,
  Table,
  Space,
  theme,
  message,
  ConfigProvider,
  Form,
  Input,
  QRCode,
} from "antd";
import {
  ProForm,
  ProFormText,
  DrawerForm,
  ProFormSelect
} from '@ant-design/pro-components';
import { CheckCircleOutlined } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationScand from '../../../../../assets/lottie/scand.json'
import theme_token from '../../../../../themes/index';

const { defaultAlgorithm, darkAlgorithm } = theme;
const { Title, Text, Paragraph } = Typography;
const numberFormatter = Intl.NumberFormat("en-US");

const Page = () => {
    const [form] = Form.useForm();
    const [ historyData, setHistoryData ] = useState(false)
    const [ totalMember, setTotalMember] = useState(0)
    const [ step, setStep] = useState(0)
    const [ accType, setAccType] = useState('other')
    const [ dataScan, setDataScan] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ menuTab, setMenuTab] = useState('Scan')
    const { authLiff, isLogin, sleep } = useContext(MainContext);

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (_, record) => (
          <Space>
            <Avatar src={record.avatar} alt="" />
            <Text>{record.email?.split('@')[0] || '-'}</Text>
          </Space>
        ),
      },
      
      {
        title: 'Date Time',
        dataIndex: 'date_rec',
        key: 'date_rec',
      },
    ];

    useEffect(() => {  
         const cache = localStorage.getItem("accType");
         if(cache){
          setAccType(cache)
         }
    }, []);   



    const onHistory = async() => {
      try {
          // const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/outing_2024/qr/history`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  setTotalMember(data.total_member)
                  setHistoryData(data.data.sort((a, b) => parseFloat(b.timestamp) - parseFloat(a.timestamp)))
              }).catch((error) => {
                setHistoryData([])
              });
      } catch(error) {
        setHistoryData([])
      }
    }


  const queryCode = async(value) => {
    // const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/outing_2024/qr/scan', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              code:value,
              sv:'none'
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==true){
                setDataScan(data.info)
                setTotalMember(data.total_member)
                setStep(1)
            }else{
              message.info(data.message);
            }
        })
        .catch((error) => {
            message.info('line not support');
        });
  }

  const onScanAction = async() => {
    try {
        setLoading(true)
        authLiff
          .scanCodeV2()
          .then(async(result) => {
                if(result && result.value){
                  await queryCode(result.value)
                  setLoading(false)
                }else{
                  setLoading(false)
                }
          }).catch((error) => {
              setLoading(false)
              message.info('line app not support camera');
          });
      
    } catch(error) {
      setLoading(false)
    }
  }

  const RenderScan = () =>{
    return (    
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
            Scan Qr ({totalMember}คน)
          </Title>
        </Col>
        <Col span={24}>
        {step==0? (
          <Card size={'small'} style={{padding:0,backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
            <Result
              icon={<Lottie options={{
                  animationData: animationScand,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
              title={'Scan QR'}
              subTitle={"กรุณาทำการกดที่ปุ่ม Scan Qr"}
              style={{padding:0}}
              extra={[
                <Button type="primary" block size="large" onClick={onScanAction} loading={loading} disabled={loading}>
                  Scan Qr
                </Button>
              ]}
            />
          </Card>
        ) :  (
          <Card size={'small'}>
            {dataScan && (<Result
              title={'Register success'}
              subTitle={'ทำการลงทะเบียนเข้างานสำเร็จ'}
              icon={
                <div style={{position:'relative',width:100,margin:'30px auto 0'}}>
                  <Avatar size={100} src={dataScan?.avatar} />
                </div>
              }
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    setStep(0);
                    setDataScan(false);
                }}>
                  Close
                </Button>
              ]}
              style={{padding:0}}
            >
              {dataScan && <div className="desc" style={{padding:0}}>
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Information:
                  </Text>
                </Paragraph>
                <Paragraph>
                  {dataScan?.name && (<><Text>Name : <Text copyable={{ text: dataScan?.name }}>{dataScan?.name}</Text></Text><br /></>)}
                  {dataScan?.employee_id && (<><Text>Employee ID : <Text copyable={{ text: dataScan?.employee_id }}>{dataScan?.employee_id}</Text></Text><br /></>)}
                </Paragraph>
              </div>}
            </Result>)}
          </Card>
        )}
        </Col>
      </Row>
    )
  }


  // /api/liff/:key/event/outing_2024/qr/history
  const RenderHistory = () =>{
    return (<div>
      <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                  History (Total {historyData.length})
                </Title>
              </Col>
              <Col span={24}>
                <Card size={'small'}>
                  {historyData && <Table dataSource={historyData} columns={columns} />}
                </Card>
              </Col>
      </Row>
    </div>)
  }

  const onFinish = async() => {
    var data = form.getFieldsValue()
    console.log('===>',data)

      setLoading(true)
      const accessToken = await authLiff.getAccessToken()
      fetch(`/api/liff/kuber/event/outing_2024/login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify(data),
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                form.resetFields();
                setAccType(data.type)
                localStorage.setItem("accType", data.type);
                message.success('Login สำเร็จ');
              }else{
                message.info('ข้อมูลไม่ถูกต้อง');
              }
          })
          .catch((error) => {
              setLoading(false)
          });
 
  }
  const onLogout=()=>{
    localStorage.removeItem("accType");
    setAccType('other')
  }

  const RenderLogin = () => {
    return (
      <Card
        style={{
          borderRadius: 12,
          padding: 2,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          color: 'white',
          marginBottom:10
        }}
      >
          <Form form={form} onFinish={onFinish}  autoComplete="off">
              <Form.Item name={'user'} label={"User Name"}  rules={[{ required: true, message: 'Missing user' }]}>
                <Input size="large"  placeholder="User"/>
              </Form.Item>
              <Form.Item name={'pass'} label={"Password"} rules={[{ required: true, message: 'Missing password' }]}>
                <Input size="large"  placeholder="Password"/>
              </Form.Item>

              <Form.Item>
                  <Button type={"primary"} size="large" htmlType="submit" icon={<CheckCircleOutlined />} block>Login</Button>
              </Form.Item>
          </Form>
      </Card>
    )
  }

  // <LinkAccount liffId={'1657440707-42EdAbdn'}>
  return (
    <LinkAccount liffId={'1657440707-42EdAbdn'} ignore={true}>
          <Spin spinning={loading} delay={500}>
          <div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/outing_2024/bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24} className="text-center">
                <img src={"/img/liff/event/outing_2024/header.svg"} style={{margin:'auto',height:100,margin:'20px auto'}}/>
              </Col>
              <Col span={24}>
                <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
                  <ConfigProvider
                    dark={true}
                    theme={{
                      algorithm: darkAlgorithm,
                      token: theme_token.dark
                    }}
                  >
                    {accType!='other' && (<Row gutter={[0, 0]} className="mb-10">
                      <Col span={24} style={{textAlign:'right'}}>
                        <Button type={"primary"} size="small" onClick={onLogout} icon={<CheckCircleOutlined />} >Logout {accType}</Button>
                      </Col>
                      <Col span={24}>
                      <Segmented
                        defaultValue="center"
                        style={{ marginBottom: 8 }}
                        onChange={async(value) => {
                          onHistory()
                          setMenuTab(value)
                        }}
                        options={accType=='admin'? ['Scan', 'History'] : ['Scan']}
                      />
                      </Col>
                      <Col span={24}>
                          {menuTab=="Scan" && (<RenderScan  />)}
                          {accType=='admin' && menuTab=="History" && (<RenderHistory />)}
                      </Col>
                    </Row>)}
                    {accType=='other' && (<RenderLogin />)}
                  </ConfigProvider>
                </div>
              </Col>
            </Row>
          </div>

        </Spin>
      </LinkAccount>
  );
};


export default Page;