import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Alert,
  Result,
  Dropdown,
  message
} from "antd";

import { CheckOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import liff from '@line/liff';
import { MainContext } from "../contexts/main";
import * as animationData from '../assets/lottie/done.json'
import '../index.css';

const { Title, Text } = Typography;

const LinkAccountComponent = ({liffId,ignore=false,children}) => {  

    const [form_email] = Form.useForm();
    const [form_verify] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [disable, setDisabled] = useState(true);
    const [session,setSession] = useState(false)
    const [step, setStep] = useState(0);
    const [mount, setMount] = useState(0);
    const [formData, setFormData] = useState({});
    const [profile,setProfile] = useState({})
    const [profileEmail,setProfileEmail] = useState(false)

    const { setIsLogin, setAuthData, authLiff, setAuthLiff } = useContext(MainContext);

    useEffect(() => {  
         init();
    }, []);    

    const init = async() => {
      setLoading(true)
        if(liff && liff.init){
          if(ignore==true){
            try{
                await liff.init({ liffId }).catch((err)=>{console.log(err)});
                setAuthLiff(liff)
                setLoading(false)
            }catch(e){
              setLoading(false)
              liff.login()
            }
          }else{
            try{
                await liff.init({ liffId }).catch((err)=>{console.log(err)});
                setAuthLiff(liff)
                let accessToken = await liff.getAccessToken()
                if(accessToken){
                    setLoading(false)
                    if(mount==0){
                      setMount(1)
                      fetch('/api/liff/kuber/link/check', {
                              method: 'GET',
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${accessToken}`
                              }
                          })
                          .then((response) => response.json())
                          .then((data) => {
                              setDisabled(false)
                              if(data.status==true){
                                setAuthData(data.res)
                                setIsLogin(true)
                                setStep(3)
                              }else{
                                setStep(0)
                              }
                              console.log('Success:', data);
                          })
                          .catch((error) => {
                              setDisabled(false)
                              console.error('Error:', error);
                          });
                    }
                }
            }catch(e){
              setLoading(false)
              liff.login()
            }
          }
        }else{
          setLoading(false)
        }
    } 

    const onFinishRequest = async({email}) => {
        console.log('onFinishRequest==>',email)
        let accessToken = await liff.getAccessToken()
        fetch('/api/liff/kuber/link/request', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                  email
                }),
            })
            .then((response) => response.json())
            .then((data) => {
              if(data.status==true){
                setSession(data.key)
                setDisabled(false)
                setStep(1)
                console.log('Success:', data);
              }else{
                message.info(data.message);
              }
            })
            .catch((error) => {
                setDisabled(false)
                console.error('Error:', error);
            });
    }

    const RenderFormRequest = () => {
        return (
          <Form form={form_email} layout="vertical" onFinish={onFinishRequest}>
            <Row gutter={[24, 0]} className="mb-10">
              <Col span={24}>
                  <Card className="mb-2">
                    <Row gutter={[12, 12]}>
                      <Col span={24} align="left">
                        <Title level={3}>Link Kuber Account</Title>
                      </Col>
                      <Col span={24} align="left">
                        <Text>Please enter your bitkub email</Text>
                      </Col>
                      <Col span={24} align="left">
                        <Form.Item name={'email'} rules={[{required: true, message: ""}]}>
                          <Input placeholder="nick.name@bitkub.com"/>
                        </Form.Item>
                      </Col>
                      <Col span={24} align="center">
                        <Button type="primary" block size="large" htmlType="submit" className={"submit"}>
                          Request Email OTP
                        </Button>
                      </Col>
                      <Col span={24} align="left">
                        <Text>กรุณาทำการกรอกอีเมลล์พนักงานเพื่อทำการผูกกับบัญชี line ของท่านโดยหลังจากที่กรอกแบบฟอร์มเรียบร้อยแล้ว กด Request Email OTP ระบบจะทำการส่งรหัส 6 ตัวไปยังอีเมลล์ของท่านโดยท่านต้องนำรหัสดังกล่าวมากรอกใน ขั้นตอนถัดไปเพื่อทำการ verify</Text>
                      </Col>
                    </Row>

                  </Card>
              </Col>
            </Row>
          </Form>
        )
    }

    const onFinishVerify = async(values) => {
        console.log('onFinish==>',values)
        var body = {
          otp:values.otp,
          key:session
        }
        console.log(body)
        setDisabled(true)
        fetch('/api/liff/kuber/link/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  otp:values.otp,
                  key:session
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                setAuthData(data.res)
                setIsLogin(true)
                setDisabled(false)
                setStep(2)
                console.log('Success:', data);
            })
            .catch((error) => {
                setDisabled(false)
                setStep(1)
                console.error('Error:', error);
            });
    }

    const RenderFormVerify = () => {
        return (
          <Form form={form_verify} layout="vertical" onFinish={onFinishVerify}>
            <Row gutter={[24, 0]} className="mb-10">
              <Col span={24}>
                  <Card className="mb-2">
                    <Row gutter={[12, 12]}>
                      <Col span={24} align="left">
                        <Title level={3}>Verify Email OTP</Title>
                      </Col>
                      <Col span={24} align="left">
                        <Text>Please input your OTP from email</Text>
                      </Col>
                      <Col span={24} align="left">
                        <Form.Item name={'otp'} rules={[{required: true, message: ""}]}>
                          <Input placeholder="xxxxxx" />
                        </Form.Item>
                      </Col>
                      <Col span={24} align="center">
                        <Button type="primary" block size="large" htmlType="submit" className={"submit"}>
                          Verify OTP
                        </Button>
                      </Col>
                    </Row>
                  </Card>
              </Col>
            </Row>
          </Form>
        )
    }

    const RenderFormSuccess = () => {
        return (
          <Card className="mb-5" size={'small'}>
            <Result
              icon={<Lottie options={{
                  animationData: animationData,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
              title="Link account success"
              subTitle="ทำการ ลิงค์ account สำเร็จ"
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    liff.closeWindow();
                }}>
                  Close
                </Button>
              ]}
            />
          </Card>
        )
    }

    return ignore==true? (
        <Spin spinning={loading} delay={500}>
            <div>
              {children}
            </div>
        </Spin>
    ) : (
        <Spin spinning={loading} delay={500}>
            <div>
              {(step==0 && disable==false) && <RenderFormRequest/>}
              {(step==1 && disable==false) && <RenderFormVerify/>}
              {step==2 && <RenderFormSuccess/>}
              {step==3 && children}
            </div>
        </Spin>
    ); 
};

export default LinkAccountComponent;

