import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  InputNumber,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Tag,
  Alert,
  Skeleton,
  FloatButton,
  Carousel,
  Result,
  Divider,
  Drawer,
  Dropdown,
  Upload,
  Rate,
  Image,
  Modal,
  message,
  theme,
  ConfigProvider
} from "antd";

import { CloseCircleOutlined, QuestionCircleOutlined,PlusOutlined, MinusCircleOutlined, UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CommentOutlined, BookTwoTone, FlagTwoTone, ArrowLeftOutlined, FrownOutlined, MehOutlined, SmileOutlined, CheckOutlined, SendOutlined, GoogleOutlined, ProfileTwoTone, TagsTwoTone, TrophyTwoTone, WalletTwoTone } from "@ant-design/icons";
import { Link, useParams, useSearchParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import FormSelect from '../../../../../components/FormSelect.jsx';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx';
import CardView from '../../../../../components/CardView.jsx';
import * as animationData from '../../../../../assets/lottie/done.json'
import './create.css';

import theme_token from '../../../../../themes/index';
const { defaultAlgorithm, darkAlgorithm } = theme;

const { Title, Text, Paragraph } = Typography;
const PageLiffKuberOutingCreate = () => {  
    let { id } = useParams();
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const [ waiting, setWaiting ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(false);
    const [ step, setStep ] = useState(1);
    const [ open, setOpen ] = useState(false);
    const [ info, setInfo ] = useState({info:{},me:0,status:false});
    const [ formData, setFormData ] = useState(false);
    const [ modal, contextHolder ] = Modal.useModal();
    const { authLiff, authData, isLogin, addressFormat } = useContext(MainContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const _id = searchParams.get('id') || '-';

    useEffect(() => {  
      if(isLogin==true){
         queryInfo(_id)
      }
    }, [isLogin]);  

    // useEffect(() => {  
    //   console.log(_id)
    //      queryInfo(_id)

    // }, []);

    const queryInfo = async(_id) => {
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/outing_2024/info/'+_id, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                var found_status = data.info.party.filter(item=>item.employee_id._id==data.me)
                console.log(found_status,data.info.party,data.me)
                setStep(found_status[0].status==true? 2 : 1)
                setInfo(data)
              }else{
                setStep(4)
                setAlertMessage(data.message)
                message.info(data.message);
              }
          })
          .catch((error) => {
              setLoading(false)
          });
    }


    const onFinish = async() => {

      const confirmed = await modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        destroyOnClose:true,
        content: `ต้องการยืนยันการทำรายการหรือไม่? `,
        okText: 'Confirm',
        cancelText: 'Cancel',
      });
      if(confirmed){
        setLoading(true)
      
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/event/outing_2024/invite/${_id}/approve`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({}),
            })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false)
                if(data.status==true){
                  setStep(3)
                  setAlertMessage(data.message)
                  message.success(data.message);
                }else{
                  setStep(4)
                  setAlertMessage(data.message)
                  message.info(data.message);
                }
            })
            .catch((error) => {
                setLoading(false)
            });
      }
    }

    const RenderViewForm = () => {
      return (
        <ConfigProvider
          dark={true}
          theme={{
            algorithm: darkAlgorithm,
            token: theme_token.dark
          }}
        >
        <Card className="mb-2" style={{backgroundColor:'#00000050',border:'none'}}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
            
               {step==1 && <RenderViewFormInvite/>}
               {step==2 && <RenderViewFormResult/>}
               {step==3 && <RenderViewFormSuccess/>}
               {step==4 && <RenderViewFormError/>}
           
              {contextHolder}
            </Col>
          </Row>
        </Card>
        </ConfigProvider>
      )
    }


    const RenderViewFormInvite = () => {
      return (
        <Result
          style={{padding:0}}
          icon={<QuestionCircleOutlined style={{color:'#ffffff'}}/>}
          title={"ยืนยันการร่วมทีม "+info?.info?.name+" ?"}
          subTitle={"ท่านต้องการ ยืนยันการร่วมทีม "+info?.info?.name+" โดยมีสมาชิกตามรายการด้านล่าง หรือไม่"}
          extra={[
             <Button type="primary" onClick={onFinish}>
              Confirm
            </Button>,
            <Button onClick={()=>{
                    authLiff.closeWindow();
                }}>
                  Close
            </Button>
          ]}
        >
          <div className="desc" style={{padding:'10px 0'}}>
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                 List Member
              </Text>
            </Paragraph>

            {info?.info?.party?.map(item=>{
              return (
                <Paragraph style={{color:'#ffffff'}}>
                  <PlusOutlined style={{color:'#ffffff'}} /> {item.name} ({item.dao})
                </Paragraph>
              )
            })}
          </div>
        </Result>
      )
    }

    const RenderViewFormResult = () => {
      return (
        <Result
          style={{padding:0}}
          icon={<Lottie options={{
              animationData: animationData,
              loop: false,
              autoplay: true,
            }} height={200} width={200}/>}
          title={"คุณได้ร่วมทีม "+info?.info?.name+""}
          subTitle={"คุณได้ร่วมทีม "+info?.info?.name+" โดยมีสมาชิกตามรายการด้านล่าง"}
          extra={[
            <Button onClick={()=>{
                    authLiff.closeWindow();
                }}>
                  Close
            </Button>
          ]}
        >
          <div className="desc" style={{padding:'10px 0'}}>
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                 List Member
              </Text>
            </Paragraph>

            {info?.info?.party?.map(item=>{
              return (
                <Paragraph style={{color:'#ffffff'}}>
                 {item.status==true? <CheckOutlined style={{color:'#ffffff'}} /> : <PlusOutlined style={{color:'#ffffff'}} />}
                 {item.name} ({item.dao})
                </Paragraph>
              )
            })}
          </div>
        </Result>
      )
    }

    const RenderViewFormSuccess = () => {
      return (
        <Result
          style={{padding:0}}
          icon={<Lottie options={{
              animationData: animationData,
              loop: false,
              autoplay: true,
            }} height={200} width={200}/>}
          title={"Join team complete"}
          subTitle={alertMessage}
          extra={[
            <Button onClick={()=>{
                    authLiff.closeWindow();
                }}>
                  Close
            </Button>
          ]}
        >
        </Result>
      )
    }

    const RenderViewFormError = () => {
      return (
        <Result
          style={{padding:0}}
          icon={<CloseCircleOutlined style={{color:'#ffffff'}}/>}
          title={"Something went wrong"}
          subTitle={alertMessage}
          extra={[
            <Button onClick={()=>{
                    authLiff.closeWindow();
                }}>
                  Close
            </Button>
          ]}
        >
        </Result>
      )
    }

    // <LinkAccount liffId={'1657440707-WRJAqQAx'}>
    return (
     <LinkAccount liffId={'1657440707-WRJAqQAx'}>
          <Spin spinning={loading} delay={500}>
          <div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/outing_2024/bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24} className="text-center">
                <img src={"/img/liff/event/outing_2024/header.svg"} style={{margin:'auto',height:100,margin:'20px auto'}}/>
              </Col>
              <Col span={24}>
                <RenderViewForm />
              </Col>
            </Row>
            </div>
          </Spin>
      </LinkAccount>
    ); 
};

export default PageLiffKuberOutingCreate;

