import React, { useCallback, useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Alert,
  Result,
  Dropdown,
  Segmented,
  theme,
  message,
  ConfigProvider,
} from "antd";

import { CheckCircleOutlined, CheckOutlined, UserOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Fullscreen, useFullscreen } from "react-fullscreen-html";
import './index.css';
import Lottie from 'react-lottie';
import { QRCode } from 'react-qrcode-logo';
import * as animationWait from '../../../assets/lottie/block_confirm.json'
import { MainContext } from "../../../contexts/main.jsx";
import theme_token from '../../../themes/index';
const { Title, Text } = Typography;
const { defaultAlgorithm, darkAlgorithm } = theme;
const Page = () => {
  const [form] = Form.useForm();
  const screen = useFullscreen();
  const [allow,setAllow] = useState(false)
  const [loading,setLoading] = useState(false)
  const [listDataReward,setListDataReward] = useState([])
  const [listDataLucky,setListDataLucky] = useState([])
  const [ accType, setAccType] = useState('other')



  const [listResult,setListResult] = useState([])
  const [listData,setListData] = useState([])
  const [activity,setActivity] = useState('init')
  const [step,setStep] = useState('init')
  const [stepSpecial,setStepSpecial] = useState('init')
  const [showBtn,setShowBtn] = useState('all')
  const [selectReword,setSelectReword] = useState(false)
  const [lucky,setLucky] = useState(false)
  const [who,setWho] = useState(false)


  const { sleep } = useContext(MainContext);

  const handleUserKeyPress = useCallback(event => {
      const { key, keyCode } = event;
      const indexActivity = {'1':'init','2':'prompt','3':'end','4':'special'}
      if(key === '1' || key === '2' || key === '3' || key === '4'){
          if(key === '1'){
            callApi()
          }
           
          setActivity(indexActivity[key]);
          console.log(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);
      }else{
        if(key === 'f'){
          screen.enter()
          console.log(`Key: ${event.key} with keycode ${event.keyCode} has been setIsFullscreen`);
        }
      }
  }, []);

  useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress);
      return () => {
          window.removeEventListener("keydown", handleUserKeyPress);
      };
  }, [handleUserKeyPress]);


  const callApi = () => {
    fetch("/api/event/outing_2024/init")
      .then((res) => res.json())
      .then((json) => {
        setAllow(json.status)
        setLucky(false)
        setSelectReword(false)
        setShowBtn('all')
        setListDataReward(json.reward)
        setListDataLucky(json.lucky)
      })
  }


  useEffect(() => {  
    const cache = localStorage.getItem("accType");
    console.log(cache)
     if(cache){
        setAccType('admin')
        callApi() 
     }

  }, []);   


  useEffect(() => {
    if(accType=='admin'){
      callApi() 
    }
  }, [accType]);

  // 1920*1080

  const onFinish = async() => {
    var data = form.getFieldsValue()
    console.log('===>',data)

      setLoading(true)
      fetch(`/api/event/outing_2024/tv_login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                form.resetFields();
                setAccType(data.type)
                localStorage.setItem("accType", data.type);
                message.success('Login สำเร็จ');
              }else{
                message.info('ข้อมูลไม่ถูกต้อง');
              }
          })
          .catch((error) => {
              setLoading(false)
          });
 
  }

  const actionRandomSpecial = async() => {
    setStepSpecial('loading')
    fetch(`/api/event/outing_2024/random_special`)
      .then((res) => res.json())
      .then(async(json) => {
        console.log(json)
        await sleep(5000)
        setWho(json.data)
        setStepSpecial('done')
      })
  }

  const actionClearSpecial = () => {
    setStepSpecial('init')
    setWho(false)
  }

  const actionRandom = async() => {
    setShowBtn('all')
    setStep('loading')
    fetch(`/api/event/outing_2024/random/${selectReword._id}`)
      .then((res) => res.json())
      .then(async(json) => {
        console.log(json)
        await sleep(5000)
        setLucky(json.lucky)
        setStep('done')
      })
  }

  const actionConfirm = () =>{
    //load pre data
    setShowBtn('confirm')
    setLoading(true)
    fetch(`/api/event/outing_2024/confirm/${selectReword._id}`)
      .then((res) => res.json())
      .then(async(json) => {
        console.log(json)
        await sleep(3000)
        setLoading(false)
        setStep('init')
        callApi()
      })
  }

  const actionCancel = () => {
    setShowBtn('cancel')
    setLoading(true)
    fetch(`/api/event/outing_2024/cancel/${selectReword.id}`)
      .then((res) => res.json())
      .then(async(json) => {
        await sleep(3000)
        setLoading(false)
        console.log(json)
        setStep('init')
        callApi()
      })
  }

  const actionSelect = (data) => {
    console.log('actionSelect==>',lucky,data)
    if(!lucky){
      setStep('prompt')
      setSelectReword(data)
    }
  }

  const actionClear = (data) => {
    setStep('init')
    setSelectReword(false)
  }

  const RanderInit = () =>{
    return (
      <>
     
          {/*<div className="grid grid-cols-5 gap-5" style={{marginTop:50}}>
            {
              listDataReward.map(item=>{
                return (
                  <div onClick={()=>actionSelect(item)}>
                     <Avatar size={80} src={item.image} shape={item.card_type} style={{ verticalAlign: 'middle', border:'none' }}   className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" />
                  </div>
                )
              })
            }
          </div>*/}
      
      </>
    )
  }

  const RanderInitSpecial = () =>{
    return (
      <>
        <div className="relative w-full">
          <Avatar size={300} style={{ backgroundColor:'#1da57a',verticalAlign: 'middle', border:'5px solid white', color:'#ffffff',fontSize:150 }}>?</Avatar>
        </div>
        <div style={{backgroundColor:'#00000050',borderRadius: 5,padding:5,margin:'10px auto 30px'}}>
          <Title style={{margin:0,padding:0,fontSize:20}}>Who Lucky?</Title>
        </div>
        <Space className="flex items-center" align="center" direction="vertical">
          <Button type="primary" size="large" onClick={actionRandomSpecial}>Random</Button>
        </Space>
      </>
    )
  }

  const RanderMatchDoneSpecial = () =>{
    return (who && who._id) && (
      <>
        <div className="relative w-full">
          <Avatar size={300} src={(who?.avatar && who.avatar!='')? who.avatar : 'https://event.bitkuber.io/icon-512x512.png'}  style={{ backgroundColor:'white',verticalAlign: 'middle', border:'5px solid white' }}/>
        </div>
        <div style={{backgroundColor:'#00000050',borderRadius: 5,padding:5,margin:'10px auto 30px'}}>
          <Title style={{margin:0,padding:0,fontSize:20}}>{who.company_email}</Title>
        </div>
        <Space className="flex items-center" align="center" direction="vertical">
          <Button type="primary" size="large" onClick={actionClearSpecial} loading={loading}>Clear</Button>
        </Space>
      </>
    )
  }

  const RanderPrompt = () =>{
    var found = selectReword
    return (found && found._id) && (
      <>
        <div className="relative w-full">
          <Avatar size={found.card_type=='square'? 350 : 300} src={found.image} shape={found.card_type}  />
        </div>
        <div style={{backgroundColor:'#00000050',borderRadius: 5,padding:5,margin:'10px auto 30px'}}>
          <Title style={{margin:0,padding:0,fontSize:20}}>{found.name}</Title>
        </div>
        <Space>
          <Button type="primary" className="btn-danger" size="large" onClick={actionClear}>Clear</Button>
          <Button type="primary" size="large" onClick={actionRandom}>Random</Button>
        </Space>
      </>
    )
  }

  const RanderMatchDone = () =>{
    return (lucky && lucky._id) && (
      <>
        <div className="relative" style={{width:400}}>
          <Avatar size={200} style={{ backgroundColor:'white',padding:lucky.card_type=='square'? 10 : 0, verticalAlign: 'middle', border:'5px solid white' }}  src={lucky.image} shape={lucky.card_type}  className="absolute -left-20 top-0" />
          <img alt="" className="h-32" src="/img/event/outing_2024/arrow_right.png" style={{margin:'40px auto'}}/>
          <Avatar size={200} style={{ backgroundColor:'white',verticalAlign: 'middle', border:'5px solid white' }}  src={(lucky.employee_id?.avatar && lucky.employee_id.avatar!='')? lucky.employee_id.avatar : 'https://event.bitkuber.io/icon-512x512.png'}  className="absolute -right-20 top-0" />
        </div>
        <div style={{backgroundColor:'#00000050',borderRadius: 5,padding:5,margin:'10px auto 30px'}}>
          <Title style={{color:'white',margin:0,padding:0}}>{lucky.employee_id.company_email}</Title>
        </div>
        <Space style={{marginTop:20}}>
          {['all','cancel'].includes(showBtn) && <Button type="primary" className="btn-danger" size="large" onClick={actionCancel} loading={loading}>Cancel</Button>}
          {['all','confirm'].includes(showBtn) && <Button type="primary" size="large" onClick={actionConfirm} loading={loading}>Confirm</Button>}
        </Space>
      </>
    )
  }

  const RanderLoading = () =>{
    return (
      <div className="relative w-full">
        <Lottie options={{
          animationData: animationWait,
          loop: true,
          autoplay: true,
        }} height={400} width={400}/>
      </div>
    )
  }

  return allow? (
  <Fullscreen handle={screen}>
      <ConfigProvider
        dark={true}
        theme={{
          algorithm: darkAlgorithm,
          token: theme_token.dark
        }}
      >
      <div className="tv-main " >

        <div className={"tv-main-body body_"+activity+" step_"+step}>
        {activity!=='init' && (<div className=" backdrop-blur-sm bg-green/80 absolute top-0 left-0 w-full h-full transition-opacity delay-150" />)}
        {activity=='prompt' && (
          <>
            <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center p-10">
                <div className="flex flex-col space-y-3" style={{width:520}}>
                  {listDataLucky.slice(0,5).map(item=>{
                    return (
                      <div style={{marginBottom:20}}>
                        <div className="match_card transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
                          <Avatar.Group style={{marginTop:'-20px'}}>
                            <Avatar size={90} src={item.image} shape={item.card_type} style={{ backgroundColor:'white',padding:item.card_type=='square'? 10 : 0, verticalAlign: 'middle', border:'5px solid white', }} />
                            <img alt="" className="h-12" src="/img/event/outing_2024/arrow_right.png" style={{margin:'20px auto'}}/>
                            <Avatar size={90} src={(item.employee_id?.avatar && item.employee_id.avatar!='')? item.employee_id.avatar : 'https://event.bitkuber.io/icon-512x512.png'} style={{ backgroundColor:'white',verticalAlign: 'middle', border:'5px solid white', }} />
                          </Avatar.Group>
                          <Text style={{color:'white',fontSize:18,fontWeight:700,marginTop:5}}>{item.employee_id.company_email}</Text>
                        </div>
                      </div>
                    )
                  })}

                </div>

                <div className="flex flex-col items-center" style={{width:350}}>
                  {step=='init' && <RanderInit />}
                  {step=='prompt' && <RanderPrompt />}
                  {step=='done' && <RanderMatchDone />}
                  {step=='loading' && <RanderLoading />}
                </div>
                <div className="flex flex-col items-end space-y-3" style={{width:520}}>
                  <div className="grid grid-cols-4 gap-6">
                    {
                      listDataReward.map(item=>{
                        return (
                          <div onClick={()=>actionSelect(item)}>
                             <Avatar size={100} src={item.image} shape={item.card_type} style={{ backgroundColor:'white',padding:item.card_type=='square'? 10 : 0,verticalAlign: 'middle', border:'5px solid white' }}   className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
            </div>
          </>
        )}
        {activity=='end' && (
          <>
            <div className="tv-main-frame">
              <div className="flex flex-col items-center" style={{marginTop:200}}>
                <div className="grid grid-cols-5 gap-5">
                      {listDataLucky.map(item=>{
                        return (
                          <div style={{marginBottom:20,width:320}}>
                            <div className="match_card transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
                              <Avatar.Group style={{marginTop:'-20px'}}>
                                <Avatar size={90} src={item.image}  shape={item.card_type} style={{ backgroundColor:'white',padding:item.card_type=='square'? 10 : 0, verticalAlign: 'middle', border:'5px solid white' }}  />
                                <img alt="" className="h-12" src="/img/event/outing_2024/arrow_right.png" style={{margin:'20px auto'}}/>
                                <Avatar size={90} src={(item.employee_id?.avatar && item.employee_id.avatar!='')? item.employee_id.avatar : 'https://event.bitkuber.io/icon-512x512.png'} style={{ backgroundColor:'white',verticalAlign: 'middle', border:'5px solid white' }}   />
                              </Avatar.Group>
                              <Text style={{color:'white',fontSize:18,fontWeight:700,marginTop:5}}>{item.employee_id.company_email}</Text>
                            </div>
                          </div>
                        )
                      })}
                </div>
              </div>
            </div>
          </>
        )}
        {activity=='special' && (
          <>
            <div className="tv-main-frame">
              <div className="flex flex-col items-center" style={{marginTop:200}}>
                <div className="absolute items-center">
                  {stepSpecial=='init' && <RanderInitSpecial />}
                  {stepSpecial=='done' && <RanderMatchDoneSpecial />}
                  {stepSpecial=='loading' && <RanderLoading />}
                </div>
              </div>
            </div>
          </>
        )}

        <img src="/img/event/outing_2024/badge_company.png" className="badge_company_name"/>

        </div>
      </div>
      </ConfigProvider>
      </Fullscreen>
  ) : (<>
    <Spin spinning={loading} delay={500}>
    <ConfigProvider
        dark={true}
        theme={{
          algorithm: darkAlgorithm,
          token: theme_token.dark
        }}
      >
      <div className="tv-main" >
        <div className={"tv-main-body body_init"}>
            <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center p-10">
              <Card
                style={{
                  borderRadius: 12,
                  padding: 2,
                  color: 'white',
                  margin:'0 auto'
                }}
              >
                  <Form form={form} onFinish={onFinish}  autoComplete="off">
                      <Form.Item name={'user'} rules={[{ required: true, message: 'Missing user' }]}>
                        <Input size="large"  placeholder="User"/>
                      </Form.Item>
                      <Form.Item name={'pass'} rules={[{ required: true, message: 'Missing password' }]}>
                        <Input.Password size="large"  placeholder="Password"/>
                      </Form.Item>

                      <Form.Item>
                          <Button type={"primary"} size="large" htmlType="submit" icon={<CheckCircleOutlined />} block>Login</Button>
                      </Form.Item>
                  </Form>
              </Card>
            </div>
        </div>
      </div>
      </ConfigProvider>
    </Spin>
  </>);


}

export default Page;
