import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  InputNumber,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Tag,
  Alert,
  Skeleton,
  FloatButton,
  Carousel,
  Result,
  Divider,
  Drawer,
  Dropdown,
  Upload,
  Rate,
  Image,
  Modal,
  message,
  theme,
  ConfigProvider
} from "antd";

import { CloseCircleOutlined, QuestionCircleOutlined,PlusOutlined, MinusCircleOutlined, UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CommentOutlined, BookTwoTone, FlagTwoTone, ArrowLeftOutlined, FrownOutlined, MehOutlined, SmileOutlined, CheckOutlined, SendOutlined, GoogleOutlined, ProfileTwoTone, TagsTwoTone, TrophyTwoTone, WalletTwoTone } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import FormSelect from '../../../../../components/FormSelect.jsx';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx';
import CardView from '../../../../../components/CardView.jsx';
import * as animationData from '../../../../../assets/lottie/done.json'

import './create.css';

import theme_token from '../../../../../themes/index';
const { defaultAlgorithm, darkAlgorithm } = theme;

const { Title, Text, Paragraph } = Typography;
const PageLiffKuberOutingCreate = () => {  
    let { id } = useParams();
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const [ info, setInfo ] = useState(false);
    const [ step, setStep ] = useState(0);
    const [ partyList, setPartyList ] = useState([]);
    const [ partyListLabel, setPartyListLabel ] = useState([]);
    const [ agree, setAgree ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ formData, setFormData ] = useState(false);
    const [ modal, contextHolder ] = Modal.useModal();
    const { authLiff, authData, isLogin, addressFormat } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         queryInfo()
      }
    }, [isLogin]);  




    const queryInfo = async() => {
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/outing_2024/init', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                setInfo(data.data)
              }else{
                message.info(data.message);
              }
          })
          .catch((error) => {
              setLoading(false)
          });
    }

    const onConfirm = async(data) => {
      var check_index = {}
      var check_empty = partyListLabel.filter(item=>item!=false)
      partyListLabel.map(item=>{
        check_index[item]=true
      })

      var data = form.getFieldsValue()
      console.log(data)
      // data.party = data.party.map(item=>{
      //   return item.type.value 
      // })
      // console.log('onConfirm data===>',data,check_empty,check_index,Object.keys(check_index).length)
      
      if(check_empty.length==3 && Object.keys(check_index).length>=3){
        setFormData(data)
        setStep(1);
      }else{
        message.info('การสร้างทีม ไม่ถูกต้องตามเงื่อนไข');
      }
    }

    const onFinish = async() => {
      const data = formData
      const confirmed = await modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        destroyOnClose:true,
        content: `ต้องการส่งแบบฟอร์มเลยหรือไม่? `,
        okText: 'Confirm',
        cancelText: 'Cancel',
      });
      if(confirmed){
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/event/outing_2024/team/create`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false)
                if(data.status==true){
                  setStep(2);
                  message.success(data.message);
                }else{
                  message.info(data.message);
                }
            })
            .catch((error) => {
                setLoading(false)
            });
      }
    }

    const onChange = (e) => {
      setAgree(e.target.checked)
    }

    const RenderViewForm = () => {
      return (
        <ConfigProvider
          dark={true}
          theme={{
            algorithm: darkAlgorithm,
            token: theme_token.dark
          }}
        >
        <Card className="mb-2" style={{backgroundColor:'#00000050',border:'none'}}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
                {!formData? <RenderViewFormInput/> : (step==1? <RenderViewFormSubmit/> : <RenderViewRes/>)}
              
              {contextHolder}
            </Col>
          </Row>
        </Card>
        </ConfigProvider>
      )
    }


    const fetchData = async(email) => {
        const accessToken = await authLiff.getAccessToken()
        return fetch('/api/liff/kuber/event/outing_2024/team/search/'+email, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify({'ignore':partyList.filter(item=>item!='')})
          })
          .then((res) => res.json())
          .then((body) =>body.data.map((user) => ({
                label: user.company_email+' ('+user.dao+')',
                value: user._id,
              }),
          ))
    }

    const handleOnChange = async(value, options, index)=>{
      partyList[index]=(value? value.value : '')
      setPartyList(partyList)

      partyListLabel[index] = (value? value.label.split(' ')[1] : false)
      setPartyListLabel(partyListLabel)

    }

    const RenderViewFormInput = () => {
     
      return (
        
          <Form form={form} onFinish={onConfirm}  initialValues={{ party: ['','',''] }} autoComplete="off">
            <Form.Item
              name="name"
              label="Team Name"
              rules={[{ required: true, message: 'Missing Name' }]}
            >
              <Input placeholder="Name" style={{width:'100%'}} size="large"/>
            </Form.Item>
            <Divider />

            {info && (
              <Form.Item label="Team Member">
                <Input disabled placeholder={info?.name+' '+info?.dao}  style={{width:'100%'}} size="large"/>
              </Form.Item>
            )}

            <Form.List name="party"  style={{width:'100%'}}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField },index) => (

                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        label="Team Member"
                        rules={[{ required: true, message: 'Missing type' }]}
                      >
                        <FormSelect
                          showSearch
                          allowClear
                          placeholder="Select user from employee email"
                          fetchOptions={fetchData}
                          style={{ width: '100%'}}
                          size="large"
                          onChange={(value, options)=>handleOnChange(value, options, index)}
                        />
                      </Form.Item>
               
                  ))}
                </>
              )}
            </Form.List>
            
            <div className="info-shadow">
              <Text style={{color:"white"}}>
                  เงื่อนไขการจัดตั้งทีม<br /><br />
                  จะต้องมีสมาชิกภายในทีมที่มาจาก Bitkub HODL Day สีละ 1 คน เท่านั้น เพื่อเข้าร่วมการแข่งขัน<br />
                  (ตัวแทนของทีมไม่ต้องกรอกชื่อของตัวเอง กรอกเพียงรายชื่อของสมาชิกภายในทีม)<br /><br />
                  ตัวอย่างเช่น<br />
                  1.สมาชิกคนที่ 1: Bullish (ตัวแทน)<br />
                  2.สมาชิกคนที่ 2: Bearish<br />
                  3.สมาชิกคนที่ 3: Moon<br />
                  4.สมาชิกคนที่ 4: Lambo<br /><br />
                  กรุณาตรวจสอบรายชื่อสมาชิกในทีมอีกครั้งก่อนกดยืนยัน เนื่องจากจะไม่สามารถแก้ไขรายชื่อสมาชิกได้จนกว่าจะสิ้นสุดกิจกรรม
              </Text>
            </div>
            <Divider />
            <Checkbox onChange={onChange} checked={agree}>ยืนยัน และยอมรับเงื่อนไข</Checkbox>

            <Divider />
            
            <Form.Item>
                <Button type={!agree? "" : "primary"} size="large" disabled={!agree} htmlType="submit" icon={<CheckCircleOutlined />} block>Submit</Button>
            </Form.Item>

          </Form>
 
      )
    }

    const RenderViewFormSubmit = () => {
      return (
        <Result
          style={{padding:0}}
          icon={<QuestionCircleOutlined style={{color:'#ffffff'}}/>}
          title={(<>ยืนยันการสร้างทีม <br />{formData.name} ?</>)}
          subTitle={"ท่านต้องการ ยืนยันการสร้างทีม "+formData.name+" โดยมีสมาชิกตามรายการด้านล่าง หรือไม่"}
          extra={[
             <Button type="primary" onClick={onFinish}>
              Confirm
            </Button>,
            <Button onClick={()=>{
                setFormData(false)
            }}>
              Close
            </Button>
          ]}
        >
          <div className="desc" style={{padding:'10px 0'}}>
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                 Total member
              </Text>
            </Paragraph>


            
            {info && (<Paragraph style={{color:'#ffffff'}}>
                  <PlusOutlined style={{color:'#ffffff'}} /> {info?.name+' '+info?.dao} 
            </Paragraph>)}
            {formData?.party?.map(item=>{
              return (
                <Paragraph style={{color:'#ffffff'}}>
                  <PlusOutlined style={{color:'#ffffff'}} /> {item.type?.label?.replace('@bitkub.com','')} 
                </Paragraph>
              )
            })}
          </div>
        </Result>
      )
    }

    const RenderViewRes = () =>{
      return (
          <Result
            title={(<>สร้างทีม <br />{formData.name} สำเร็จ</>)}
            subTitle={'ท่านได้สร้างกลุ่มเรียบร้อยแล้ว ระบบจะทำการส่ง Link ไปยังสมาชิกเพื่อกด Join เข้าทีมในการแข่งขัน (กรุณากด Join ภายใน 3 วันหลังจากกด Submit สร้างทีม) '}
            icon={<Lottie options={{
              animationData: animationData,
              loop: false,
              autoplay: true,
            }} height={200} width={200}/>}
            extra={[
              <Button type="primary" block size="large" onClick={()=>{
                  setStep(0);
                  form.resetFields();
                  setFormData(false)
                  setAgree(false)
              }}>
                Close
              </Button>
            ]}
            style={{padding:0}}
          >
          <div className="desc" style={{padding:'10px 0'}}>
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                 Total member
              </Text>
            </Paragraph>
            {info && (<Paragraph style={{color:'#ffffff'}}>
                  <PlusOutlined style={{color:'#ffffff'}} /> {info?.name+' '+info?.dao} 
            </Paragraph>)}
            {formData?.party?.map(item=>{
              return (
                <Paragraph style={{color:'#ffffff'}}>
                  <PlusOutlined style={{color:'#ffffff'}} /> {item.type?.label?.replace('@bitkub.com','')} 
                </Paragraph>
              )
            })}
          </div>
          </Result>
      )
    }



    // <LinkAccount liffId={'1657440707-mLQV8PVr'}>
    return (
        <LinkAccount liffId={'1657440707-mLQV8PVr'}>
          <Spin spinning={loading} delay={500}>
          <div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/outing_2024/bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24} className="text-center">
                <img src={"/img/liff/event/outing_2024/header.svg"} style={{margin:'auto',height:100,margin:'20px auto'}}/>
              </Col>
              <Col span={24}>
                <RenderViewForm />
              </Col>
            </Row>
            </div>
          </Spin>
        </LinkAccount>

    ); 
};

export default PageLiffKuberOutingCreate;

