import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  InputNumber,
  Table,
  Space,
  Checkbox,
  Segmented,
  List,
  Spin,
  Avatar,
  Radio,
  Tag,
  Alert,
  Skeleton,
  FloatButton,
  Carousel,
  Result,
  Divider,
  Drawer,
  Dropdown,
  Upload,
  Rate,
  Image,
  Modal,
  theme,
  message,
  ConfigProvider
} from "antd";

import { CrownOutlined, StarFilled, CloseCircleOutlined, QuestionCircleOutlined,PlusOutlined, MinusCircleOutlined, UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CommentOutlined, BookTwoTone, FlagTwoTone, ArrowLeftOutlined, FrownOutlined, MehOutlined, SmileOutlined, CheckOutlined, SendOutlined, GoogleOutlined, ProfileTwoTone, TagsTwoTone, TrophyTwoTone, WalletTwoTone } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import CardView from '../../../../../components/CardView.jsx'
import theme_token from '../../../../../themes/index';
import * as animationData from '../../../../../assets/lottie/done.json'

import './quiz.css';

const numberFormatter = Intl.NumberFormat("en-US");


const { defaultAlgorithm, darkAlgorithm } = theme;
const { Title, Text, Paragraph } = Typography;
const PageLiffKuberFormKhaya = () => {  
    let { id } = useParams();
    const [form] = Form.useForm();
    const [cards, setCards] = useState(["card1", "card2", "card3"]);
    const [cardList, setCardList] = useState(false);
    const [teamInfo, setTeamInfo] = useState(false);
    const [meInfo, setMeInfo] = useState(false);
    const [myScore,setMyScore] = useState(0);
    const [teamScore,setTeamScore] = useState(0);
    const [myTime,setMyTime] = useState('00:00:00');
    const [teamTime,setTeamTime] = useState('00:00:00');
    const [animateDone, setAnimateDone] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ waiting, setWaiting ] = useState(false);
    const [ agree, setAgree ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ step, setStep ] = useState(0);
    const [ alertMessage, setAlertMessage ] = useState('');

    const [ frame, setFrame ] = useState(1);
    const [ teamScoreData,setTeamScoreData] = useState(false);
    const [ formData, setFormData ] = useState(false);
    const [ menuTab, setMenuTab ] = useState('Quiz');

    const [ meScore, setMeScore ] = useState(0);
    const [ listScore, setListScore ] = useState(false);
    const [ teamName, setTeamName ] = useState(false);
    const [ listMember, setListMember ] = useState(false);


    const [ modal, contextHolder ] = Modal.useModal();
    const { authLiff, authData, isLogin, addressFormat } = useContext(MainContext);

    const [quizView, setQuizView] = useState(false);
    const [quizData, setQuizData] = useState(false);

    const [dataScore,setDataScore] = useState(false);

    useEffect(() => {  
      if(isLogin==true){
         quizInit()
      }
    }, [isLogin]);  
    // useEffect(() => {  
    //      quizInit()
    // }, []); 

    useEffect(() => {  
      if(menuTab=='Score Board'){
        scoreInit()
      }
      if(menuTab=='Team Member'){
        memberInit()
      }
    }, [menuTab]); 


    const quizInit = async() => {
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/outing_2024/quiz/init', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                console.log('data.quiz==>',data,data.point)
                setCardList(data.data.quiz)
                setTeamInfo(data.data.team)
                setMeInfo(data.data.me)
                setMyScore(data.point.my)
                setTeamScore(data.point.team)
                setMyTime(data.time.my)
                setTeamTime(data.time.team)
                if(data.concent==1){
                  setStep(2)
                }
                var found_quiz = data.data.quiz.filter(item=>item.current && item.status==true)
                console.log('data.found_quiz==>',found_quiz)
                if(found_quiz?.length==1){
                  setQuizData(found_quiz[0])
                  setQuizView(true)
                  setAnimateDone(true)
                }
                
               
              }else{
                setStep(data.step)
                setAlertMessage(data.message)
                message.info(data.message);
              }
          })
          .catch((error) => {
              setLoading(false)
          });
    }

    const scoreInit = async() => {
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/outing_2024/score/init', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                console.log('data.score==>',data,data)
                setMeScore(data.data.me)
                setListScore(data.data.list)
              }else{
                message.info(data.message);
              }
          })
          .catch((error) => {
              setLoading(false)
          });
    }

    const memberInit = async() => {
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/outing_2024/score/team', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                console.log('data.score==>',data,data)
                setTeamName(data.data.name)
                setListMember(data.data.list)
              }else{
                message.info(data.message);
              }
          })
          .catch((error) => {
              setLoading(false)
          });
    }

    const handleCardClick = () => {
      // Iterate over cards with animation delay
      if(animateDone==false){
        cards.forEach((_, index) => {
          setTimeout(() => {
            const cardElement = document.querySelector(`.${cards[index]}`);
            if (cardElement) {
              cardElement.classList.add("pop");

              // Show message after the last card's animation ends
              if (index === cards.length - 1) {
                cardElement.addEventListener(
                  "animationend",
                  () => {
                    console.log('end')
                    setAnimateDone(true);
                  },
                  { once: true }
                );
              }
            }
          }, index * 300); // Delay increases by 300ms per card
        });
      }
    };

    const RenderHeader = () => {
      return (
        <div>
              <Card className="wallet_card" style={{backgroundImage: `url(/img/wallet/KBC.png)`}}>
                <Text style={{color:'white'}}>Hello krit.chakkrit</Text><br />
                <Title level={2} className="ma-0 mr-10" style={{color:'white'}}>Event : Khaya x Kuber </Title>
              </Card>
        </div>
      )
    }

    const onFinish = async() => {
      var data = form.getFieldsValue()
      console.log('===>',data)
      const confirmed = await modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        destroyOnClose:true,
        content: `ต้องการส่งคำตอบเลยหรือไม่? `,
        okText: 'Confirm',
        cancelText: 'Cancel',
      });
      if(confirmed){
        setWaiting(true)
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/event/outing_2024/quiz/submit/${quizData.id}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                setWaiting(false)
                if(data.status==true){
                  form.resetFields();
                  setStep(3)
                  message.success(data.message);
                }else{
                  message.info(data.message);
                }
            })
            .catch((error) => {
                setWaiting(false)
            });
      }
    }

    const onChange = (e) => {
      setAgree(e.target.checked)
    }

    const normFile = (e) => {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
    };


    const RenderConsent = () => {
      return (

          <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
            <Row gutter={[16, 16]}>
              <Col span={24} key={'index-0'}>
                <Card
                  style={{
                    borderRadius: "12px",
                    width:'100%'
                  }}
                >
                  <div>
                    <Title level={4} style={{textAlign:'center'}}>วัตถุประสงค์ในการแข่งขัน</Title>
                    <Paragraph>
                                Road to OneBitkub Outing & Staff Party 2024 เป็นกิจกรรม
                      อุ่นเครื่องก่อนถึงงาน Outing ในวันที่ 13-14 ธันวาคม 2024ความตั้งใจที่อยากจะให้ Bitkubers ทุกคนได้รู้จักกันมากขึ้น 
                      ผ่านการรวมกลุ่มกับเพื่อนต่างทีม เพื่อพิชิต Mission ต่างๆ 
                      และสะสมคะแนนให้ได้มากที่สุด โดยทุกคนจะได้มีโอกาสนำ Bitkub’s Core Values มาใช้เพื่อแก้ปัญหาและร่วมมือกันเพื่อก้าวสู่ชัยชนะไปด้วยกัน
                    </Paragraph>
                    <Paragraph>
                      มาเตรียมพร้อมเข้าสู่งาน OneBitkub Outing & Staff Party 2024 งานใหญ่ที่สุดส่งท้ายปีกันไปด้วยกันค่ะ 💚
                    </Paragraph>
                  </div>
                </Card>
              </Col>
              <Col span={24} style={{textAlign:'center'}}>
                <Button
                  type="primary"
                  size="large"
                  onClick={()=>setStep(1)}
                  style={{
                    backgroundColor: "#4CAF50",
                    borderColor: "#4CAF50",
                    borderRadius: "12px",
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
      )
    }

    const RenderRule = () => {
      return (

          <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  style={{
                    borderRadius: "12px",
                    width:'100%'
                  }}
                >
                  <div>
                    <Title level={4} style={{textAlign:'center'}}>กติกาการแข่งขัน</Title>
                    <Paragraph>
                    <ol>
                        <li>ผู้เล่นต้องทำการสุ่มการ์ด จากกองการ์ดทั้งหมด โดยระบบจะสุ่มการ์ดให้จำนวน 10 ใบ หากได้รับการ์ดดาวทอง ผู้เล่นจะได้รับคะแนนทันทีโดยไม่ต้องทำ Mission เพิ่ม<br /><br /></li>

                        <li>Mission Card สามารถทำได้เพียงวันละ 1 ใบ เท่านั้น โดยการ์ดแต่ละใบมีระยะเวลาทำ Mission เพียง 24 ชั่วโมง เท่านั้น หากไม่ทำ Mission ภายในเวลาที่กำหนด การ์ดนั้นจะถือเป็นโมฆะทันทีและจะไม่ได้รับคะแนน<br /><br /></li>

                        <li>เวลาจะเริ่มนับตั้งแต่ผู้เล่นกดเปิดการ์ด Mission และขณะทำ Mission ระบบจะจับเวลาอย่างต่อเนื่อง แม้ผู้เล่นจะปิดหน้าจอ เวลาจะยังคงนับต่อไป<br /><br /></li>

                        <li>คะแนนส่วนตัว (My Point) คะแนนทีม (Team Point)  และ เวลารวมที่ใช้สำหรับการทำ Mission (Total mission time) จะแสดงที่มุมขวาบนของหน้าจอ<br /><br /></li>

                        <li>Leaderboard จะแสดงผลเฉพาะทีมที่ติดอันดับ TOP 10 โดยระบบจะพิจารณาจากคะแนนรวมของทีมและระยะเวลาที่ใช้ในการทำ Mission ตัวอย่างเช่น หาก Team A และ Team B มีคะแนนเท่ากันที่ 2,000 คะแนน  แต่ Team B ใช้เวลาน้อยกว่า Team A ทีม B จะได้รับการจัดอันดับสูงกว่า<br /><br /></li>

                        <li>ทีมที่ติดอันดับ TOP 10 จะได้ผ่านเข้าสู่รอบ Final Round ซึ่งเป็นเกมตามล่าหาสมบัติ ที่จัดขึ้นในวันที่ 13 ธันวาคม 2567 ณ โรงแรม Centara Grand Mirage Beach Resort Pattaya<br /><br /></li>

                        <li>หากทีมที่ติดอันดับ TOP 10 มีสมาชิกที่ไม่สามารถเข้าร่วมรอบ Final ได้ จะถือว่าทีมนั้นหมดสิทธิ์ในการแข่งขันรอบ Final ทันที (ไม่สามารถเปลี่ยนสมาชิกในทีมได้)<br /><br /></li>

                        <li>ผู้เข้าร่วมการแข่งขันทุกคน ต้องอ่านและทำความเข้าใจกติกาการเล่น  เมื่อกดปุ่ม Accept จะถือว่าคุณเข้าใจและยอมรับในกติกาทั้งหมด<br /><br /></li>

                        <li>ผลคะแนนจาก Leaderboard จะถือเป็นที่สิ้นสุดและไม่สามารถเปลี่ยนแปลงได้</li>
                    </ol>
                    </Paragraph>
                  </div>
                </Card>
              </Col>


              <Col span={24} style={{textAlign:'center'}}>
                <Button
                  type="primary"
                  size="large"
                  onClick={()=>setStep(2)}
                  style={{
                    backgroundColor: "#4CAF50",
                    borderColor: "#4CAF50",
                    borderRadius: "12px",
                  }}
                >
                  Accept
                </Button>
              </Col>
            </Row>
          </div>
      )
    }

    const onViewCard = async(item)=>{
      if(item.status==true){
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch('/api/liff/kuber/event/outing_2024/quiz/'+item.id, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                }
            })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false)
                if(data.status==true){
                  console.log('data.quiz==>',data)
                  console.log(item.quiz)
                  setQuizData(item)
                  setQuizView(true)
                  message.info(data.message);
                }else{
                  message.info(data.message);
                }
            })
            .catch((error) => {
                setLoading(false)
            });
      }else{
        message.info('คุณได้เล่นการ์ดนี้ไปแล้ว');
      }
    }

    const onCloseCard = (item)=>{
      setQuizView(false)
    }

    const RenderViewHolder = () => {
      return (

          <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  style={{
                    borderRadius: "12px",
                    width:'100%'
                  }}
                >
                  <Space
                    split={<Divider style={{height:'100%'}} type="vertical" />}
                    size={"small"}
                    align={"top"}
                  >
                      <Space direction={'vertical'} size={0}>
                          <Title level={5} >{meInfo.name}</Title>
                          <Text>Team : {teamInfo.name}</Text>
                      </Space>
                      <Space direction={'vertical'} size={0}>
                        <Text>My Point: {myScore}</Text>
                        <Text>Team Point : {teamScore}</Text>
                        <Text>Total Mission Time : {myTime}</Text>
                      </Space>
              
                  </Space>
                </Card>
              </Col>
              {animateDone==false && (<Col span={24} style={{textAlign:'center'}}>
                <div className="container">
                  <div className="card-container" onClick={handleCardClick}>
                    {cards.map((card, index) => (
                      <div key={index} className={`card ${card}`} />
                    ))}
                  </div>

                </div>
                <Text onClick={handleCardClick}>Click to pick up your card.</Text>
              </Col>)}
            </Row>

            {(quizView==false && animateDone==true) && (
                <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
                  <Row gutter={[16, 16]}>
                    {cardList?.map((card,index) => (
                      <Col key={'select-'+card.id} span={12}>
                        <div key={'card-'+index} className={card.status==true? `card-block` : `card-block-done`} onClick={()=>onViewCard(card)} />
                      </Col>)
                    )}
                  </Row>
                </div>
            )}



            {(quizView==true && animateDone==true) && (
              <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
                <Form form={form} onFinish={onFinish}  autoComplete="off">
                  <Row gutter={[16, 16]}>
                  
                      <Col span={24} className="text-center">
                        <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                            นับถอยหลัง 23:59 hrs.
                        </Text>
                        <div key={'card-view'} className={`card-block`} style={{margin:'10px auto'}} onClick={onCloseCard}/>
                      </Col>
                      <Col>
                
                        {quizData?.quiz?.map((quiz) => (
                          <Card
                            key={'quiz-'+quiz?.id}
                            style={{
                              borderRadius: 12,
                              padding: 2,
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              color: 'white',
                              marginBottom:10
                            }}
                          >
                            {quiz?.image!='0' && (
                              <div style={{ margin: "20px 0" }} className="text-center">
                                <img
                                  src={"/img/liff/event/outing_2024/quiz/"+quiz?.image+'.png'}
                                  alt="Quiz Cover"
                                  style={{ borderRadius: "12px", width: "150px",margin:"10px auto" }}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom:20
                              }}
                            >
                              <Text style={{margin:0,padding:0,fontWeight: "bold"}}>{`${quiz?.name}`}</Text>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  width:120
                                }}
                              >
                                <Text style={{margin:0,padding:0}}><StarFilled style={{ color: "#fadb14",marginRight:5 }} /> {quiz?.point} Points</Text>
                              </div>
                            </div>
                            {quiz?.choice?.length>=1 && (
                              <Form.Item
                                name={quiz?._id}
                                rules={[{ required: true, message: 'Missing answer' }]}
                              >
                                <Radio.Group size="large">
                                  <Space direction="vertical">
                                  {quiz?.choice?.map((label, index) => (
                                    <Radio
                                      key={'quiz-radio-'+index}
                                      value={label}
                                      style={{
                                        textAlign: "left",
                                        borderRadius: "12px",
                                      }}
                                    >
                                      {label}
                                    </Radio>
                                  ))}
                                  </Space>
                                </Radio.Group>
                              </Form.Item>
                            )}
                          </Card>
                        ))}

                        <Form.Item>
                            <Button type={"primary"} size="large" htmlType="submit" icon={<CheckCircleOutlined />} block>{quizData.quiz?.length==1? "Get Points" : "Submit Quiz"}</Button>
                        </Form.Item>

                      </Col>
                  </Row>
                </Form>
                {contextHolder}
              </div>
            )}
           
          </div>
      )
    }


  const RenderScore = () => {
    return listScore && (
      <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
        <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                  Score Board
                </Title>
              </Col>
              <Col span={24}>
                <Row gutter={[8, 8]}>
                  {listScore?.map((item, index) => {
                    return index<=4? (
                      <Col span={24} key={index}>
                        <Card
                          style={index==0? {backgroundColor:'#01B35A50'} : {backgroundColor:'#73737350'}}
                          bodyStyle={{ padding: "4px 16px" }}
                          bordered={false}
                          onClick={()=>{
                            setTeamScoreData(item);
                            setFrame(2);
                          }}
                        >
                          <Row justify={"space-between"} align={"middle"}>
                            <Col style={{ width: 170 }}>
                              <Space size={0} wrap={true}>
                                <Title level={3} ellipsis={true} style={{margin:0,padding:0,fontSize:14}}>#{index + 1} {item.name}</Title>
                              </Space>
                            </Col>
                            <Col style={{ width: 120 }}>
                   
                                <Space
                                  direction="vertical"
                                  size={2}
                                  style={{ textAlign: "right" }}
                                >
                                  <Text strong={"true"}>{numberFormatter.format((item.point || 0))} Point</Text>
                                </Space>
                       
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ) : (
                      <Col span={24} key={index}>

                          <Row justify={"space-between"} align={"middle"} onClick={()=>{
                            setTeamScoreData(item);
                            setFrame(2);
                          }}>
                            <Col style={{ width: 200 }}>
                              <Space size={0}>
                                <Title level={3} style={{margin:0,padding:0,fontSize:14,marginLeft:20}}>#{index + 1} {item.name}</Title>
                              </Space>
                            </Col>
                            <Col style={{ width: 120 }}>
                                <Space
                                  direction="vertical"
                                  size={2}
                                  style={{ textAlign: "right" }}
                                >
                                  <Text strong={"true"} style={{marginRight:20}}>{numberFormatter.format((item.point || 0))} Point</Text>
                                </Space>
                            </Col>
                          </Row>
                    
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            </Row>
        </div>
      )
    }


  const RenderTeam = () => {
    return listMember && (
      <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
        <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                  Team Member 
                </Title>
                {listMember.length>=1 && <Title level={3} ellipsis={true} style={{margin:0,padding:0,fontSize:14}}> ( {teamName} )</Title>}
              </Col>
              <Col span={24}>
                <Row gutter={[8, 8]}>
                  {listMember.length>=1 ? listMember?.map((item, index) => {
                    return (
                      <Col span={24} key={index}>
                        <Card
                          style={index==0? {backgroundColor:'#01B35A50'} : {backgroundColor:'#73737350'}}
                          bodyStyle={{ padding: "4px 16px" }}
                          bordered={false}
                        >
                          <Row justify={"space-between"} align={"middle"}>
                            <Col style={{ width: 170 }}>
                              <Space size={0} wrap={true}>
                                <Title level={3} ellipsis={true} style={{margin:0,padding:0,fontSize:14}}>#{index + 1} {item.name}</Title>
                              </Space>
                            </Col>
                            <Col style={{ width: 120 }}>
                   
                                <Space
                                  direction="vertical"
                                  size={2}
                                  style={{ textAlign: "right" }}
                                >
                                  <Text strong={"true"}>{numberFormatter.format((item.point || 0))} Point</Text>
                                </Space>
                       
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )
                  }) : (
                    <Col span={24}>
                      <Result
                        style={{padding:0}}
                        icon={<QuestionCircleOutlined style={{color:'#ffffff'}}/>}
                        title={(<>ไม่พบข้อมูล</>)}
                        subTitle={"คุณไม่ได้สร้างทีมเข้าร่วมกิจกรรม"}
                      >
                      </Result>
                    </Col>
                  )}
                </Row>
              </Col>
        </Row>
      </div>
      )
    }

    const RenderScoreView = () => {
      return teamScoreData && (
      <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
              {teamScoreData?.name}
            </Title>
          </Col>
          <Col span={24}>
              <Row gutter={[8, 8]}>
                      {teamScoreData?.me && (
                        <Col span={24}>
                        <Card
                          style={{backgroundColor:'#73737350'}}
                          bodyStyle={{ padding: "4px 16px" }}
                          bordered={false}
                        >
                          <Row justify={"space-between"} align={"middle"}>
                            <Col style={{ width: 170 }}>
                              <Space size={0} wrap={true}>
                                <Title level={3} ellipsis={true} style={{margin:0,padding:0,fontSize:14}}>My Point</Title>
                              </Space>
                            </Col>
                            <Col style={{ width: 120 }}>
                   
                                <Space
                                  direction="vertical"
                                  size={2}
                                  style={{ textAlign: "right" }}
                                >
                                  <Text strong={"true"}>{numberFormatter.format((teamScoreData?.point || 0))} Point</Text>
                                </Space>
                       
                            </Col>
                          </Row>
                        </Card>
                        </Col>
                      )}
                      <Col span={24}>
                        <Card
                          style={{backgroundColor:'#73737350'}}
                          bodyStyle={{ padding: "16px" }}
                          bordered={false}
                        >
                          <Row justify={"space-between"} align={"middle"}>
                            <Col style={{ width: 170 }}>
                              <Space size={0} wrap={true}>
                                <Title level={3} ellipsis={true} style={{margin:0,padding:0,fontSize:14}}>Team Point</Title>
                              </Space>
                            </Col>
                            <Col style={{ width: 120 }}>
                   
                                <Space
                                  direction="vertical"
                                  size={2}
                                  style={{ textAlign: "right" }}
                                >
                                  <Text strong={"true"}>{numberFormatter.format((teamScoreData?.point || 0))} Point</Text>
                                </Space>
                       
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={24}>
                        <Card
                          style={{backgroundColor:'#73737350'}}
                          bodyStyle={{ padding: "16px" }}
                          bordered={false}
                        >
                          <Row justify={"space-between"} align={"middle"}>
                            <Col style={{ width: 170 }}>
                              <Space size={0} wrap={true}>
                                <Title level={3} ellipsis={true} style={{margin:0,padding:0,fontSize:14}}>Total Mission Time</Title>
                              </Space>
                            </Col>
                            <Col style={{ width: 120 }}>
                   
                                <Space
                                  direction="vertical"
                                  size={2}
                                  style={{ textAlign: "right" }}
                                >
                                  <Text strong={"true"}>{teamScoreData?.time} Sec</Text>
                                </Space>
                       
                            </Col>
                          </Row>
                        </Card>
                      </Col>
              </Row>
          </Col>
          <Col span={24} style={{textAlign:'center'}}>
            <Button
              type="primary"
              size="large"
              onClick={()=>setFrame(1)}
              style={{backgroundColor: "#4CAF50",borderColor: "#4CAF50",borderRadius: "12px"}}
            >
              Back
            </Button>
          </Col>
        </Row>
      </div>
      )
    }

    const RenderViewDone = () => {
      return (
          <Result
            title={(<>สำเร็จ</>)}
            subTitle={'คุณได้ทำการเล่นการ์ดสำหรับวันนี้แล้ว'}
            icon={<Lottie options={{
              animationData: animationData,
              loop: false,
              autoplay: true,
            }} height={200} width={200}/>}
            extra={[
              <Button type="primary" size="large" onClick={()=>{
                  authLiff.closeWindow();
              }}>
                Close
              </Button>
            ]}
            style={{padding:0}}
          >
          </Result>
      )
    }

    const RenderViewFalse = () => {
      return (
         <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
            <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                      Quiz
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <Result
                          style={{padding:0}}
                          icon={<QuestionCircleOutlined style={{color:'#ffffff'}}/>}
                          title={(<>ไม่พบข้อมูล</>)}
                          subTitle={"คุณไม่ได้สร้างทีมเข้าร่วมกิจกรรม"}
                        >
                        </Result>
                      </Col>
                    </Row>
                  </Col>
            </Row>
        </div>
      )
    }


    const onActionEnd = async(join) => {
      const confirmed = await modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        destroyOnClose:true,
        content: join=='accept'? `ต้องการยืนยันการเข้าร่วม แข่งขันรอบไฟนอล เลยหรือไม่? ` : `ต้องการยืนยันการไม่เข้าเข้าร่วม แข่งขันรอบไฟนอล เลยหรือไม่? `,
        okText: 'Confirm',
        cancelText: 'Cancel',
      });
      if(confirmed){
        setWaiting(true)
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/event/outing_2024/join`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({join}),
            })
            .then((response) => response.json())
            .then((data) => {
                setWaiting(false)
                if(data.status==true){
                  setStep(6)
                  setAlertMessage(data.message)
                  message.success(data.message);
                }else{
                  message.info(data.message);
                }
            })
            .catch((error) => {
                setWaiting(false)
            });
      }
    }

    const onApprove = async() => {
      await onActionEnd('accept')
    }

    const onReject = async() => {
      await onActionEnd('reject')
    }

    const RenderViewConfirm = () => {
      return (
         <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
            <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                      แข่งขันรอบไฟนอล
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <Result
                          style={{padding:0}}
                          icon={<CrownOutlined style={{color:'#ffffff'}}/>}
                          title={"ยืนยันการเข้าร่วมแข่งขันรอบไฟนอล"}
                          subTitle={"คุณต้องการยืนยันการส่งชื่อทีมของท่าน เข้าร่วมแข่งขันรอบไฟนอลในวันงาน OneBitkub Outing & Staff Party At Pattaya หรือไม่ ?"}
                          extra={[
                            <Button danger size="large" onClick={onReject}>
                              Reject
                            </Button>,
                            <Button type="primary" size="large" onClick={onApprove} >
                              Confirm
                            </Button>
                          ]}
                        >
                        </Result>
                      </Col>


                      <Col span={24}>
                        <Card
                          style={{
                            borderRadius: 12,
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            color: 'white',
                            marginBottom:10
                          }}
                        >
                          <Text>เงื่อนไข<br /><br />
                            <ul>
                                <li>ให้ผู้สร้างทีมเป็นคนดำเนินการ "ยืนยันเข้าร่วมกิจกรรม" หรือ "ขอสละสิทธิ์" สำหรับการแข่งขันรอบท้าชิงในวันที่ 13 ธันวาคม 2567 </li>
                                <li>สมาชิกทุกคนในทีมจะต้องเข้าร่วมงาน OneBitkub Outing & Staff Party 2024</li>
                                <li>ไม่สามารถเปลี่ยนแปลงสมาชิกในทีมได้หลังจากกดยืนยัน</li> 
                                <li>กรุณากดยืนยันการเข้าร่วม ภายในวันที่ 12 ธันวาคม 2567 เวลา 17:00 น. หากยืนยันหลังจากเวลานี้ ทางทีมงานขอสงวนสิทธิ์ในการตัดสิทธิ์ทีมของท่านจากการแข่งขัน</li>
                            </ul>
                          </Text>
                        </Card>
                      </Col>
                      {contextHolder}






                    </Row>
                  </Col>
            </Row>
        </div>
      )
    }

    const RenderViewEnd = () => {
      return (
         <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
            <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                      แข่งขันรอบไฟนอล
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <Result
                          style={{padding:0}}
                          icon={<QuestionCircleOutlined style={{color:'#ffffff'}}/>}
                          title={(<>แข่งขันรอบไฟนอล</>)}
                          subTitle={alertMessage}
                        >
                        </Result>
                      </Col>
                    </Row>
                  </Col>
            </Row>
        </div>
      )
    }


    // 
    return (
      <LinkAccount liffId={'1657440707-90YayPa8'}>
        <Spin spinning={loading} delay={500}>
          <div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/outing_2024/bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24} className="text-center">
                <img src={"/img/liff/event/outing_2024/header.svg"} style={{margin:'auto',height:100,margin:'20px auto'}}/>
              </Col>

              <Col span={24}>
                <ConfigProvider
                  dark={true}
                  theme={{
                    algorithm: darkAlgorithm,
                    token: theme_token.dark
                  }}
                >
                  <div style={{ padding: "0 20px", maxWidth: "500px", margin: "auto" }}>
                    <Row gutter={[16, 16]}>
                      <Col span={24} key={'index-0'}>
                          <Segmented
                            defaultValue="Quiz"
                            style={{ marginBottom: 8 }}
                            onChange={async(value) => {
                              setFrame(1)
                              setMenuTab(value)
                            }}
                            options={['Quiz', 'Team Member', 'Score Board']}
                          />
                      </Col>
                    </Row>
                  </div>
                  {(menuTab=='Quiz' && step==0) && <RenderConsent />}
                  {(menuTab=='Quiz' && step==1) && <RenderRule />}
                  {(menuTab=='Quiz' && step==2) && <RenderViewHolder />}
                  {(menuTab=='Quiz' && step==3) && <RenderViewDone />}
                  {(menuTab=='Quiz' && step==4) && <RenderViewFalse />}
                  {(menuTab=='Quiz' && step==5) && <RenderViewConfirm />}
                  {(menuTab=='Quiz' && step==6) && <RenderViewEnd />}
                  {(menuTab=='Team Member' && frame==1) && <RenderTeam />}
                  {(menuTab=='Score Board' && frame==1) && <RenderScore />}
                  {(menuTab=='Score Board' && frame==2) && <RenderScoreView />}
                </ConfigProvider>
              </Col>
            </Row>
          </div>
        </Spin>
      </LinkAccount>
    ); 
};

export default PageLiffKuberFormKhaya;

