import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Typography,
  Result,
  QRCode,
  Spin,
  Space,
  theme,
  message,
  ConfigProvider,
  Descriptions
} from "antd";

import { PhoneOutlined, ZoomInOutlined, ZoomOutOutlined, CloseOutlined, CarOutlined, AlertOutlined, ScanOutlined, HomeOutlined, CalendarOutlined, EnvironmentOutlined, ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom'
import { MiniMap, TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx';
import theme_token from '../../../../../themes/index';

import './menu.css';

const { defaultAlgorithm, darkAlgorithm } = theme;

const { Title, Text, Paragraph } = Typography;
const PageLiffKuberOutingMenu = () => {  
    const [ loading, setLoading ] = useState(false);
    const [ view, setView ] = useState(false);
    const [ dataQr, setDataQr ] = useState(false);
    const [ dataRoommate, setRoommateData ] = useState(false);  
    const [ dataAgenda, setAgendaData ] = useState(false);
    const [ dataJourney, setJourneyData ] = useState(false);
    const [ dataChecklist, setChecklistData ] = useState(false);
    const [ dataDodont, setDodontData ] = useState(false);
    const [ dataBus, setBusData ] = useState(false);
    const [ dataEmergency, setEmergencyData ] = useState(false);

    const { authLiff, authData, isLogin } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
        setLoading(false)
      }
    }, [isLogin]);  

    const RenderFrame = ({onClose, children}) => {
      return (
        <div style={{ padding: "20px 20px 100px", maxWidth: "500px", margin: "auto" }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
                {children}
            </Col>
            <Col span={24} style={{textAlign:'center'}}>
              <Button
                type="primary"
                size="large"
                onClick={()=>onClose()}
                style={{backgroundColor: "#4CAF50",borderColor: "#4CAF50",borderRadius: "12px"}}
              >
                Back
              </Button>
            </Col>
          </Row>
        </div>
      )
    }

    const onClickQr = async() => {
      setLoading(true)
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/outing_2024/qr/gen`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  setLoading(false)
                  if(data.status==true){
                    setDataQr(data)
                    setView('qr')
                  }
              }).catch((error) => {
                setLoading(false)
              });
      } catch(error) {
        setLoading(false)
      }

      // setView('qr')
      // setDataQr({data:'test',info:{
      //   name:'krit.chakkrit',
      //   dao:'Lambo'
      // }})
    }

    const onCloseQr = async() => {
      setView(false)
      setDataQr(false)
    }

    const onClickRoommate = async() => {
      setLoading(true)
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/outing_2024/roommate`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  setLoading(false)
                  if(data.status==true){
                    setRoommateData(data.data)
                    setView('roommate')
                  }
              }).catch((error) => {
                setLoading(false)
              });
      } catch(error) {
        setLoading(false)
      }


      // setRoommateData({
      //   name:"Room No. 1001",
      //   mate:[
      //     { name: "bowl.pacharawit" },
      //     { name: "golf.maythat" },
      //     { name: "tiz.satit" }
      //   ]
      // })
    }

    const onCloseRoommate = async() => {
      setView(false)
      setRoommateData(false)
    }

    const onClickAgenda = async() => {
      setView('agenda')
      setAgendaData(true)
    }

    const onCloseAgenda = async() => {
      setView(false)
      setAgendaData(false)
    }

    const onClickJourney = async() => {
      setView('journey')
      setJourneyData(true)
    }

    const onCloseJourney = async() => {
      setView(false)
      setJourneyData(false)
    }

    const onClickChecklist = async() => {
      setView('checklist')
      setJourneyData(true)
    }

    const onCloseChecklist = async() => {
      setView(false)
      setJourneyData(false)
    }

    const onClickDodont = async() => {
      setView('dodont')
      setDodontData(true)
    }

    const onCloseDodont = async() => {
      setView(false)
      setDodontData(false)
    }

    const onClickBus = async() => {
      setLoading(true)
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/outing_2024/bus`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  setLoading(false)
                  if(data.status==true){
                    setBusData(data.data)
                    setView('bus')
                  }
              }).catch((error) => {
                setLoading(false)
              });
      } catch(error) {
        setLoading(false)
      }

    }

    const onCloseBus = async() => {
      setView(false)
      setBusData(false)
    }

    const onClickEmergency = async() => {
      // message.info('ข้อมุลส่วนนี้พร้อมเปิดใช้วันงาน');
      setView('emergency')
      setEmergencyData(true)
    }

    const onCloseEmergency = async() => {
      setView(false)
      setEmergencyData(false)
    }

    const RenderViewQr = ({title,onClose}) => {
      return view=='qr' && (
          <RenderFrame onClose={onClose}>
            {dataQr && dataQr.data && (<Result
              icon={dataQr?.data && (<div style={{padding:0,display: 'flex',justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                <Card>
                  <QRCode
                    errorLevel="H"
                    bordered={false}
                    size={250}
                    value={dataQr?.data}
                  />
                </Card>
              </div>)}
              title={(<Title style={{color:'#ffffff'}}>QR Code</Title>)}
              subTitle={(
                <Space style={{textAlign:'center',marginTop:30}} direction={'vertical'}>
                  <Text style={{fontSize:20,color:'#ffffff'}}>{dataQr?.info?.name}<br />{dataQr?.info?.employee_id}</Text>
                </Space>
              )}
              style={{padding:0}}
            />)}
          </RenderFrame>
      )
    }

    const RenderViewRoommate = ({title,onClose}) => {
      return view=='roommate' && (
        <RenderFrame onClose={onClose}>
          <Row gutter={[16, 16]}>
            <Col span={24} key={'index-0'}>
                <Title level={4}>ข้อมูลการเข้าพัก</Title>
                { dataRoommate && <Title level={1} style={{marginTop:0}}>{dataRoommate?.name}</Title>}
                { dataRoommate && <Title level={4}>Your Roommate</Title>}
            </Col>
            {dataRoommate ? dataRoommate?.mate?.map((mate, index) => (
              <Col span={24} key={index}>
                <Card
                  style={{
                    borderRadius: "12px",
                    width:'100%'
                  }}
                >
                  <Title level={4} style={{margin:0,padding:0}}>{mate.name}</Title>
                </Card>
              </Col>
            )) : (
              <Col span={24} key={'index-x'}>
                <Card
                  style={{
                    borderRadius: "12px",
                    width:'100%'
                  }}
                >
                  <Text>ไม่พบการจองห้องพักของท่าน</Text>
                </Card>
              </Col>
            )}
          </Row>
        </RenderFrame>
      )
    }
    const RenderViewAgenda = ({title,onClose}) => {
       const schedule = [{
          date:"13 December 2024",
          list:[
            // {time:"8:30-9:00",event:"Open Register and tag your bag at the Zilla Space for Bitkubers intending to travel by bus only." },   
            // {time:"9:00 - 9:30",event:"The bus departs from FYI Center"},
            // {time:"12:30 - 14:30",event:"Lunch at Restaurant (Moom Aroi Nakluea)"},
            // {time:"14:30 - 15:30",event:"Check-in at Centara Grand Mirage Beach Resort Pattaya"},
            // {time:"15:30 - 16:30",event:"Join Water Park, Activities, and Relax"},
            // {time:"17:30 Onwards",event:"Time to Bull Run Spirit Party and Dinner"},
            // {time:"18:30 - 18:40",event:"Opening OneBitkub Outing & Staff Party 2024"},
            // {time:"18:40 - 18:50",event:"Fire Show & Aloha Show"},
            // {time:"18:50 - 19:15",event:"Costume Contest"},
            // {time:"19:15 - 19:20",event:"Announced the Winner of Leaderboard"},
            // {time:"19:20 - 20:00",event:"Lucky Draw Time"},
            // {time:"20:00 - 21:30",event:"Concert: WAN WANWAN"},
            // {time:"21:30 - 22:00",event:"EDM Playlist"},
            // {time:"22:00 - 0:00",event:"Break Down and Free Time"}

            {time:"8:30-9:00",event:"ลงทะเบียน พร้อมติด Tag กระเป๋า สำหรับท่านที่เดินทางด้วยรถบัส ณ Zilla Space, FYI Center"},
            {time:"9:00-9:30",event:"ออกเดินทางจาก FYI Center"}, 
            {time:"9:30-12:30",event:"เดินทางสู่ร้านมุมอร่อย สาขานาเกลือ"},
            {time:"12:30-14:00",event:"ทานอาหารกลางวันที่ร้านมุมอร่อย สาขานาเหลือ"},
            {time:"14:30-15:30",event:"Check-in เข้าโรงแรม"},
            {time:"15:30-17:00",event:"พักผ่อนตามอัธยาศัย (Centara Water Park & Lost World)"},
            {time:"16:00-17:00",event:"แข่งขัน \"The Lost Treasure Hunt\" (Final Round)"},
            {time:"17:00-17:30",event:"นัดหมาย Bitkubers ถ่ายรูปร่วมกัน **สถานที่ถ่ายรูป จะแจ้งให้ทราบอีกครั้ง"},

            {time:"17:30",event:"Bull Run Spirit Party เริ่มแล้ว"},
            {time:"18:30-18:40",event:"เปิดงาน OneBitkub Outing & Staff Party 2024"},
            {time:"18:40-18:50",event:"รับชมการแสดงพิเศษ \"Bull Run Spirit\""},
            {time:"18:50-19:15",event:"ประกวดการแต่งกาย \"Bull Run Spirit\""},
            {time:"19:15-19:20",event:"ประกาศรางวัลกิจกรรม \"The Lost Treasure Hunt\""},
            {time:"19:20-20:20",event:"ลุ้นรับรางวัล หาผู้โชคดีในกิจกรรม \"Lucky Draw\""},
            {time:"20:20-22:00",event:"คอนเสิร์ตจากคุณว่าน วันวาน"},
            {time:"22:00",event:"จบงาน Bull Run Spirit Party"}

          ]
        },
        {
          date:"14 December 2024",
          list:[       
            // {time:"6:00 - 11:00",event:"Breakfast"},
            // {time:"11:00 - 12:00",event:"Check-out at Centara Grand Mirage Pattaya"},
            // {time:"12:00 - 12:15",event:"Get on the Bus"},
            // {time:"12:15 - 12:45",event:"Go to Restaurant (Krua Ban Pa Boon 2)"},
            // {time:"12:45 - 14:30",event:"Lunch at Krua Ban Pa Boon 2"},
            // {time:"14:30 - 15:00",event:"Get on the Bus"},
            // {time:"15:00 - 17:00",event:"Go to FYI Center"}

            {time:"6:30 - 11:00",event:"ทานอาหารเช้า"},
            {time:"11:00 - 12:00",event:"Check-out เตรียมออกจากโรงแรม"},
            {time:"12:00 - 12:15",event:"ขึ้นรถบัส ออกจากโรงแรม"},
            {time:"12:15 - 12:45",event:"เดินทางไปทานอาหาร ที่ร้านครัวป้าบุญ 2"},
            {time:"12:45 - 14:15",event:"ทานอาหารกลางวัน ที่ร้านครัวป้าบุญ 2"},
            {time:"14:45 - 15:00",event:"ขึ้นรถบัสกลับ FYI Center"},
            {time:"15:00 - 17:00",event:"เดินทางกลับ FYI Center"}
          ]
        }];
      return view=='agenda' && (
        <RenderFrame onClose={onClose}>
        <div style={{ maxWidth: "600px", margin: "auto" }}>
        <div className="event-title">
              Agenda
        </div>
        {schedule.map((item, index) => (
          <>
            <Title level={3} style={{ textAlign: "center", margin: "20px auto" }}>
              {item.date}
            </Title>
            <Card className="card-aganda">
              <Descriptions column={1} bordered={false}  size={'small'}>
                {item.list.map((item, index) => (
                  <Descriptions.Item
                    key={index}
                    labelStyle={{width:150,fontweight:700,color:'#ffffff',fontWeight:700}}
                    label={
                      <Text className="event-label">
                        {item.time}
                      </Text>
                    }
                  >
                    <Text style={{ color: "white", fontSize: "14px" }}>
                      {item.event}
                    </Text>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Card>
          </>
        ))}
        <div className="text-center">
          <img src={"/img/liff/event/outing_2024/footer.png?a=1"} style={{margin:'auto',width:'80%',margin:'20px auto'}}/>
        </div>
        </div>
        </RenderFrame>
      )
    }

    const Controls = ({onClose}) => {
      const { zoomIn, zoomOut, resetTransform } = useControls();

      return (
        <>
          <div style={{
            position: "fixed",
            zIndex: 5,
            left: "20px",
            top: "20px",
          }}>
            <Button.Group>
              <Button onClick={() => zoomIn()} icon={<ZoomInOutlined />}/>
              <Button onClick={() => zoomOut()} icon={<ZoomOutOutlined />}/>
              <Button onClick={() => resetTransform()} icon={<CloseOutlined/>}/>
            </Button.Group>
          </div>
          <div style={{
            position: "fixed",
            zIndex: 5,
            right: "20px",
            top: "20px",
          }}>
            <Button.Group>
              <Button onClick={() => onClose()} icon={<CloseOutlined/>}/>
            </Button.Group>
          </div>
        </>
      );
    };

    const RenderViewJourney = ({title,onClose}) => {
      return view=='journey' && (
          <TransformWrapper
            initialPositionX={-590}
          >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
                <div
                  style={{
                    position: "fixed",
                    zIndex: 5,
                    right: "20px",
                    bottom: "20px",
                  }}
                >
                  <MiniMap width={200}>
                    <div
                      style={{
                        background : 'url(/img/liff/event/outing_2024/journey.png?a=1)',
                        color: "white",
                        width: 1600,
                        height: 900
                      }}
                    >
                    </div>

                  </MiniMap>
                </div>
                <Controls onClose={onClose}/>
                <TransformComponent
                  wrapperStyle={{
                    maxWidth: "100%",
                    maxHeight: "100vh",
                  }}
                >
                    <div
                      style={{
                        background : 'url(/img/liff/event/outing_2024/journey.png?a=1)',
                        color: "white",
                        width: 1600,
                        height: 900
                      }}
                    >
                    </div>
                </TransformComponent>
           </>
          )}
          </TransformWrapper>
      )
    }
    const RenderViewChecklist = ({title,onClose}) => {
      return view=='checklist' && (
        <RenderFrame onClose={onClose}>
          <Title>{title}</Title>
        </RenderFrame>
      )
    }
    const RenderViewDodont = ({title,onClose}) => {
      return view=='dodont' && (
        <RenderFrame onClose={onClose}>
          <Title>{title}</Title>
        </RenderFrame>
      )
    }
    const RenderViewBus = ({title,onClose}) => {
      return view=='bus' && (
        <RenderFrame onClose={onClose}>
          <Card>
            {dataBus.length>=1? dataBus?.map((schedule, index) => (
              <>
                <Title level={4} style={{ textAlign: "center", margin: "20px auto" }}>
                  {schedule.date}
                </Title>
                <Descriptions column={1} bordered size={'small'}>
                  <Descriptions.Item label="PASSENGER" labelStyle={{width:120,fontweight:700,color:'#ffffff',fontWeight:700}}>
                    <Text>{schedule.name}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={schedule.type=='bus'? "BUS No." : "VAN No."} labelStyle={{width:120,fontweight:700,color:'#ffffff',fontWeight:700}}>
                    <Text>{schedule.busNo}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="FROM" labelStyle={{width:120,fontweight:700,color:'#ffffff',fontWeight:700}}>
                    <Text>{schedule.from}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="TO" labelStyle={{width:120,fontweight:700,color:'#ffffff',fontWeight:700}}>
                    <Text>{schedule.to}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={schedule.timeLabel} labelStyle={{width:120,fontweight:700,color:'#ffffff',fontWeight:700}}>
                    <Text>{schedule.time}</Text>
                  </Descriptions.Item>
                </Descriptions>
              </>
            )) : (
              <div
                style={{
                  padding:'40px auto',
                }}
              >
                <Title level={4} style={{margin:0,padding:0,textAlign:'center'}}>ไม่พบรายการของรถโดยสารของท่าน</Title>
              </div>
            )}
            <div style={{ margin: "20px 20px" }}>
              <Text>โปรดติดตามข้อมูลการเดินทางของท่านอีกครั้ง<br /><br />รายละเอียดเพิ่มเติม:<br />
                <ul>
                    <li>กรุณาตรงต่อเวลานัดหมาย </li>
                    <li>เตรียมตัวให้พร้อมก่อนขึ้นรถบัส</li>
                    <li>สวมใส่ริชแบนด์ตลอดระยะเวลาเข้าร่วมกิจกรรม</li>
                    <li>หากท่านต้องการเปลี่ยนแปลงการเดินทางหรือความช่วยเหลืออื่น ๆ โปรดแจ้งสตาฟที่ดูแลประจำรถของท่าน</li>
                </ul>
              </Text>
            </div>
          </Card>
        
        </RenderFrame>
      )
    }
    const RenderViewEmergency = ({title,onClose}) => {
      return view=='emergency' && (
        <RenderFrame onClose={onClose}>
          <Row gutter={[16, 16]}>
            <Col span={24} key={'index-0'}>
                <Title level={4}>{title}</Title>
            </Col>
            <Col span={24}>
              <a href="tel:+66863941464">
              <Card style={{borderRadius: "12px",width:'100%'}}>
                <PhoneOutlined style={{fontSize: '23px',float:'right',color:'#ffffff'}}/>
                <Title level={4} style={{margin:0,padding:0}}>คุณเปอร์</Title>
                <Text style={{margin:0,padding:0}}>0863941464</Text>
              </Card>
              </a>
            </Col>
            <Col span={24}>
              <a href="tel:+66814349087">
              <Card style={{borderRadius: "12px",width:'100%'}}>
                <PhoneOutlined style={{fontSize: '23px',float:'right',color:'#ffffff'}}/>
                <Title level={4} style={{margin:0,padding:0}}>คุณตีตี้</Title>
                <Text style={{margin:0,padding:0}}>0814349087</Text>
              </Card>
              </a>
            </Col>
          </Row>
          <div style={{ margin: "20px 20px" }}>
              <Text>** เบอร์ฉุกเฉิน ติดต่อ (ได้ตลอดเวลา) ในช่วงเวลาจัดกิจกรรม</Text>
          </div>
        </RenderFrame>
      )
    }
    const RenderViewMenu = () => {
      return view==false && (
          <Row gutter={[16, 16]} style={{ padding: "20px" }}>
            {cards.filter(item=>item.status==true).map((card, index) => (
              <Col xs={12} sm={8} md={8} lg={8} key={index}>
                <Card
                  hoverable
                  style={{
                    borderRadius: "12px",
                    textAlign: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={card.onClick}
                >
                  <div style={{ fontSize: "36px", marginBottom: "10px" }}>
                    {card.icon}
                  </div>
                  <div style={{ fontSize: "16px", fontWeight: "500" }}>
                    {card.title}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
      )
    }

    const cards = [
      { status:true, icon: <ScanOutlined />, title: "สแกนเข้าร่วมงาน", onClick: onClickQr, onClose: onCloseQr },
      { status:true, icon: <HomeOutlined />, title: "Roommate", onClick: onClickRoommate, onClose: onCloseRoommate },
      { status:true, icon: <CarOutlined />, title: "Check My Bus", onClick: onClickBus, onClose: onCloseBus },
      { status:true, icon: <CalendarOutlined />, title: "Agenda", onClick: onClickAgenda, onClose: onCloseAgenda },
      { status:true, icon: <EnvironmentOutlined />, title: "Journey", onClick: onClickJourney, onClose: onCloseJourney },
      { status:false, icon: <CheckOutlined />, title: "Checklist", onClick: onClickChecklist, onClose: onCloseChecklist },
      { status:false, icon: <ExclamationCircleOutlined />, title: "Do's/Don'ts", onClick: onClickDodont, onClose: onCloseDodont },
      { status:true, icon: <AlertOutlined />, title: "Emergency Contact", onClick: onClickEmergency, onClose: onCloseEmergency },
    ];

    // <LinkAccount liffId={'1657440707-nx1ODMOY'}>
    return (
        <LinkAccount liffId={'1657440707-nx1ODMOY'}>
          <Spin spinning={loading} delay={500}>
            {view!='journey' && (<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/outing_2024/bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
              <Row gutter={[0, 0]} className="mb-10">
                <Col span={24} className="text-center">
                  <img src={"/img/liff/event/outing_2024/header.svg"} style={{margin:'auto',height:100,margin:'20px auto'}}/>
                </Col>
                <Col span={24}>
                    <ConfigProvider
                      dark={true}
                      theme={{
                        algorithm: darkAlgorithm,
                        token: theme_token.dark
                      }}
                    >
                      <RenderViewMenu />
                      <RenderViewQr title={"สแกนเข้าร่วมงาน"} onClose={onCloseQr}/>
                      <RenderViewRoommate title={"Roommate"} onClose={onCloseRoommate}/>
                      <RenderViewAgenda title={"Agenda"} onClose={onCloseAgenda}/>
                      <RenderViewChecklist title={"Checklist"} onClose={onCloseChecklist}/>
                      <RenderViewDodont  title={"Do's/Don'ts"} onClose={onCloseDodont}/>
                      <RenderViewBus title={"Check My Bus"} onClose={onCloseBus}/>
                      <RenderViewEmergency title={"Emergency Contact"} onClose={onCloseEmergency}/>
                    </ConfigProvider>
                </Col>
              </Row>
            </div>)}

            <RenderViewJourney title={"Journey"} onClose={onCloseJourney}/>
          </Spin>
        </LinkAccount>
    ); 
};

export default PageLiffKuberOutingMenu;

